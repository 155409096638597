import React from "react";
import { PageProps } from "gatsby";
import { LayoutOnBoarding, SEO, CategoryView } from "@components";
import { useAuth } from "@hooks";
import { useMediaQuery } from "@material-ui/core";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { getUserMissions } from "@apollo";
import { MissionsInfo } from "@data";

const AhorroInversion: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { userMissions } = getUserMissions();

  const missions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.SavingsInvestmentCategory.name
  );

  return (
    <>
      <SEO
        title="Rokipedia - Ahorro e inversión"
        description="Encontrarás herramientas e información que te permitirán entender y manejar tu ahorro e inversión de la mejor forma."
      />
      {!isMobile ? (
        <LayoutOnBoarding activeRokinpedia>
          <CategoryView
            missions={missions}
            categoryInfo={MissionsInfo.SavingsInvestmentCategory}
          />
        </LayoutOnBoarding>
      ) : (
        <LayoutMobileInvestments activeIncent>
          <CategoryView
            missions={missions}
            categoryInfo={MissionsInfo.SavingsInvestmentCategory}
          />
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default AhorroInversion;
